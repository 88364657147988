<template>
  <div class="d-inline-block">
    <div class="d-inline-block" :class="{ hide: responsive && hideOnMobile }" v-if="date.till && date.since">
      <v-btn v-if="withTimezone && timezoneTextPosition == 'left'" id="calendar-timezone" small flat @click="showDialog(0)" class="mr-4 ml-0">
        <span class="text-transform-none">Timezone: </span>
        <strong class="ml-1"> {{ timezoneText }}</strong>
      </v-btn>
      <v-btn id="calendar-from-date" small flat @click="showDialog(0)" class="mr-1 ml-0">
        <strong>{{ date.since | moment("ll") }}</strong>
        <strong v-if="withTime" class="ml-1">{{ timePicker.since }}</strong>
      </v-btn>
      -
      <v-btn id="calendar-to-date" small flat @click="showDialog(1)" class="mr-0 ml-0">
        <strong>{{ date.till | moment("ll") }}</strong>
        <strong v-if="withTime" class="ml-1">{{ timePicker.till }}</strong>
      </v-btn>
      <v-btn v-if="withTimezone && timezoneTextPosition == 'right'" id="calendar-timezone" small flat @click="showDialog(0)" class="ml-4 mr-0">
        <span class="text-transform-none">Timezone: </span>
        <strong class="ml-1"> {{ timezoneText }}</strong>
      </v-btn>
    </div>
    <v-menu offset-y transition="slide-y-transition" bottom>
      <template v-slot:activator="{ on }">
        <v-btn id="calendar-icon" icon class="mr-0" v-on="on">
          <v-icon>date_range</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-tile
          :id="preset.elementId"
          v-for="(preset, index) in presets"
          :key="index"
          @click="updateSpan(preset.span, index)"
          :class="{ 'menu-active': preset.id === selected }"
        >
          <v-list-tile-title>{{ preset.name }}</v-list-tile-title>
        </v-list-tile>
        <v-list-tile
          id="calendar-list-custom"
          @click="
            customTabs = 0;
            customDialog = true;
          "
          :class="{ 'menu-active': selected === null }"
        >
          <v-list-tile-title>Custom</v-list-tile-title>
        </v-list-tile>
      </v-list>
    </v-menu>
    <v-dialog v-model="customDialog" :max-width="withTime ? 610 : 290" lazy>
      <v-card>
        <v-card-title id="calendar-dialog-title"><h3>Custom Date Range</h3></v-card-title>
        <v-card-text class="py-0">
          <v-autocomplete
            v-if="withTimezone"
            class="my-4"
            v-model="timezone"
            :items="variables.timezones"
            item-text="name"
            item-value="id"
            label="Time Zone"
            outline
            no-data-text="No timezone available"
          ></v-autocomplete>
          <v-tabs v-model="customTabs" grow :style="`${withTimezone ? 'border-top:1px solid #eaeaea' : ''}`">
            <v-tabs-slider :color="roleSetting.color" persistent></v-tabs-slider>
            <v-tab id="calendar-dialog-date-from" :key="0" ripple>
              DATE FROM
            </v-tab>
            <v-tab id="calendar-dialog-date-to" :key="1" ripple>
              DATE TO
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="customTabs" class="pt-3" id="calendar-dialog-date-picker">
            <v-tab-item :key="0">
              <v-layout row wrap justify-center>
                <v-date-picker v-if="customSince" class="elevation-0" scrollable :color="roleSetting.color" v-model="customSince" :max="customTill" no-title reactive>
                </v-date-picker>
                <v-time-picker v-if="withTime" v-model="timePicker.since" full-width color="green darken-1" :class="{ 'ml-4': !responsive }"></v-time-picker>
              </v-layout>
            </v-tab-item>
            <v-tab-item :key="1">
              <v-layout row wrap justify-center>
                <v-date-picker
                  v-if="customTill"
                  class="elevation-0"
                  scrollable
                  :color="roleSetting.color"
                  v-model="customTill"
                  :min="customSince"
                  no-title
                  reactive
                ></v-date-picker>
                <v-time-picker v-if="withTime" v-model="timePicker.till" full-width color="green darken-1" :class="{ 'ml-4': !responsive }"></v-time-picker>
              </v-layout>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-card-actions class="mt-4" style="border-top:1px solid #eaeaea">
          <template>
            <v-spacer></v-spacer>
            <v-btn id="calendar-dialog-ok" color="green darken-1" flat="flat" @click="customDialog = false">
              OK
            </v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import helpers from "@/mixins/helpers";
import { mapGetters } from "vuex";
import timezones from "@/data/timezones.json";
import { cloneDeep } from 'lodash-es';

export default {
  name: "DateRange",
  props: {
    withTime: {
      default: false,
      type: Boolean,
    },
    hideOnMobile: {
      default: true,
      type: Boolean,
    },
    withTimezone: {
      default: false,
      type: Boolean,
    },
    timezoneTextPosition: {
      default: "right",
      type: String,
    },
    showPresets: {
      default: () => ["today", "yesterday", "last_7_days", "last_30_days", "last_365_days"],
      type: Array 
    },
    defaultDateRange: {
      type: Object, // in format of { since: unix, till: unix } 
    }
  },
  mixins: [helpers],
  data: () => ({
    date: {
      since: null,
      till: null,
    },
    timePicker: {
      since: "00:00",
      till: "00:00",
    },
    selected: null,
    dateDialog: false,
    customDialog: false,
    customTabs: null,
    loaded: false,
    variables: {
      timezones: timezones.map((v) => ({ name: v.text, id: v.value })),
    },
  }),
  computed: {
    ...mapGetters("Auth", {
      roleSetting: "getRoleSetting",
    }),
    ...mapGetters("Global", {
      timezoneText: "getTimezoneText",
    }),
    storedTime() {
      return this.$store.getters.storedTime;
    },
    timezone: {
      get() {
        return this.$store.state.Global.timezone;
      },
      set(v) {
        this.$store.dispatch("Global/setTimezone", v);
      },
    },
    presets() {
      const availablePresets = [
        {
          id: "today",
          name: "Today",
          elementId: "calendar-list-today",
          span: {
            since: this.$moment().startOf("day").unix(),
            till: this.$moment().endOf("day").unix(),
          },
        },
        {
          id: "yesterday",
          name: "Yesterday",
          elementId: "calendar-list-yesterday",
          span: {
            since: this.$moment().subtract(1, "day").startOf("day").unix(),
            till: this.$moment().subtract(1, "day").endOf("day").unix(),
          },
        },
        {
          id: "last_12_hours",
          name: "Last 12hrs",
          elementId: "calendar-list-last-12-hours",
          span: {
            since: this.$moment().subtract(12, "hours").set({ second: 0 }).unix(),
            till: this.$moment().set({ second: 59 }).unix(),
          },
        },
        {
          id: "last_24_hours",
          name: "Last 24hrs",
          elementId: "calendar-list-last-24-hours",
          span: {
            since: this.$moment().subtract(24, "hours").set({ second: 0 }).unix(),
            till: this.$moment().set({ second: 59 }).unix(),
          },
        },
        {
          id: "last_7_days",
          name: "Last 7 days",
          elementId: "calendar-list-last-7-days",
          span: {
            since: this.$moment().subtract(7, "day").startOf("day").unix(),
            till: this.$moment().endOf("day").unix(),
          },
        },
        {
          id: "last_30_days",
          name: "Last 30 days",
          elementId: "calendar-list-last-30-days",
          span: {
            since: this.$moment().subtract(30, "day").startOf("day").unix(),
            till: this.$moment().endOf("day").unix(),
          },
        },
        {
          id: "last_365_days",
          name: "Last 365 days",
          elementId: "calendar-list-last-365-days",
          span: {
            since: this.$moment().subtract(365, "day").startOf("day").unix(),
            till: this.$moment().endOf("day").unix(),
          },
        },
      ];

      return availablePresets.filter(preset => this.showPresets.includes(preset.id))
    },
    responsive() {
      return this.$store.state.responsive;
    },
    customSince: {
      get() {
        return this.date.since ? this.$moment.unix(this.date.since).format("YYYY-MM-DD") : "";
      },
      set(val) {
        this.date.since = this.$moment(`${val} ${this.withTime ? this.timePicker.since : "00:00"}:00`).unix();
      },
    },
    customTill: {
      get() {
        return this.date.till ? this.$moment.unix(this.date.till).format("YYYY-MM-DD") : "";
      },
      set(val) {
        this.date.till = this.$moment(`${val} ${this.withTime ? this.timePicker.till : "23:59"}:59`).unix();
      },
    },
    dateCount() {
      if (this.date.since && this.date.till) {
        return this.$moment(this.date.till).diff(this.$moment(this.date.since), "days");
      }
      return 0;
    },
  },
  watch: {
    customSince() {
      this.change();
    },
    customTill() {
      this.change();
    },
    timePicker: {
      deep: true,
      handler(time) {
        if (this.withTime) this.change();
      },
    },
    storedTime: {
      handler(val) {
        this.customSince = this.$moment.unix(val.since).format("YYYY-MM-DD");
        this.customTill = this.$moment.unix(val.till).format("YYYY-MM-DD");
      },
      deep: true,
    },
    customDialog(v) {
      this.$emit("show", v)
    }
  },
  methods: {
    updateSpan(span) {
      this.$nextTick(() => {
        if (this.withTime) {
          this.timePicker.since = this.$moment.unix(span.since).format("HH:mm")
          this.timePicker.till = this.$moment.unix(span.till).format("HH:mm")
        }
        this.date = cloneDeep(span);
      });
    },
    change() {
      this.selected = null;
      if (this.customSince && this.customTill) {
        let newDate = {
          since: this.$moment(`${this.customSince} ${this.withTime ? this.timePicker.since: "00:00"}:00`).unix(),
          till: this.$moment(`${this.customTill} ${this.withTime ? this.timePicker.till : "23:59"}:59`).unix()
        };
        let storedTime = cloneDeep(this.storedTime);

        this.$nextTick(() => {
          if (this.loaded && storedTime.till === newDate.till && storedTime.since === newDate.since) {
          } else {
            this.$store.dispatch("setTime", newDate);
            this.loaded = true;
          }
        });
        this.presets.forEach((preset) => {
          if (newDate.since === preset.span.since && newDate.till === preset.span.till) {
            this.selected = preset.id
          }
        });
      }
    },
    showDialog(tab = 0) {
      this.customTabs = tab;
      this.customDialog = true;
    },
  },
  mounted() {
    if (this.defaultDateRange && this.defaultDateRange.since && this.defaultDateRange.till) {
      this.updateSpan(this.defaultDateRange)
    } else if (!this.storedTime.since && !this.storedTime.till) {
      const selectedPreset = "last_30_days"
      if (this.$route.name === "owner-dashboard") selectedPreset = "last_7_days";
      this.updateSpan(this.presets.find(preset => preset.id === selectedPreset))
    } else {
      this.updateSpan(this.storedTime)
    }
    this.$nextTick(() => {
      this.customTabs = null;
    });
  },
};
</script>

<style lang="scss">
.date-range-text {
  font-size: 13px;
  font-weight: 500;
  display: inline;
}
.text-transform-none {
  text-transform: none;
}
</style>
