<template>
  <v-dialog v-model="isVisible" max-width="500">
    <v-card>
      <v-card-title>
        <h3>Change Nickname for {{ (selected || {}).email }}</h3>
      </v-card-title>
      <v-alert :value="error" type="error" dismissible>{{ error }}</v-alert>
      <v-card-text>
        <v-form ref="changeNicknameForm" @submit="submit()">
          <v-text-field
            v-model="nickname"
            @keyup.stop.enter="submit()"
            label="Nickname"
            outline
            autocomplete="false"
            required
            :rules="[validators.required]"
            :error="!!error"
            :disabled="loading"
            class="mb-0"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="gray" flat="flat" @click="isVisible = false">Cancel</v-btn>
        <v-spacer />
        <v-btn color="green darken-1" flat="flat" :loading="loading" @click.stop.prevent="submit()">Confirm</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { validators } from "@/library/helpers"
export default {
  name: "ChangeNickname",
  props: {
    value: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Object | null,
      default: () => ({})
    }
  },
  data() {
    return {
      error: null,
      nickname: null
    };
  },
  computed: {
    validators: () => validators,
    isVisible: {
      get() {
        return this.value
      },
      set(v) {
        if (!v) this.nickname = null
        this.$emit('input', v)
      }
    },
    loading() {
      return false // for now just return false
    }
  },
  methods: {
    submit() {
      if (!this.$refs.changeNicknameForm.validate()) return
      console.log("submitted", this.nickname)
      this.$emit("done")
      this.isVisible = false
    }
  }
}
</script>
