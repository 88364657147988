<template>
  <div>
    <v-btn
      class="plugzio-button create-button mx-0 px-3 mb-1 mt-1 mr-2"
      small
      dark
      color="green"
      style="min-width: auto; height: 30%; border-radius: 30px;"
      round
      outline
      :disabled="!allowCreate"
      @click.stop.prevent="openModal()"
    >
      <v-icon size="20" class="ma-0" key="icon">create_new_folder</v-icon>
      <span
        class="ml-1"
        key="description"
        :class="{
          'hidden-md-and-down': !alwaysShowButtonText,
        }"
      >
        CREATE WALLET
      </span>
    </v-btn>
    <v-dialog v-if="!showWithdrawalProfiles" v-model="createWalletModal" max-width="500" persistent no-click-animation>
      <v-card>
        <v-card-title>
          <h3>{{ isEdit ? "Edit" : "Create" }} Wallet</h3>
          <VTooltipHandler classes="ml-2" icon="info" :text="role == 'owner' ? tooltip.owner : tooltip.user" />
        </v-card-title>
        <v-alert :value="walletError" type="error" dismissible>{{ walletError }}</v-alert>
        <v-card-text>
          <v-form ref="createWalletForm" @submit="createWallet()" v-model="valid">
            <CurrencyInput
              v-if="!isEdit"
              v-model="wallet.country"
              label="Currency"
              ref="currencyInput"
              :rules="currencyValidators"
              :error="!!walletError"
              :disabled="loading"
              outline
              required
            />
            <v-text-field
              v-model="wallet.description"
              @keyup.stop.enter="createWallet()"
              label="Wallet Name"
              outline
              autocomplete="false"
              required
              ref="walletDescription"
              :rules="[rules.required]"
              :error="walletError ? true : false"
              :disabled="loading"
              class="mb-0"
            />
            <div v-if="isEdit">
              <v-flex class="d-inline-flex">
                <strong class="white-space__nowrap d-flex align-center">
                  Hide & Disable Wallet
                  <VTooltipHandler classes="ml-2" style="display: flex; justify-content: flex-start;" icon="info" :text="tooltip.hideWallet" />
                </strong>
              </v-flex>
              <v-select
                v-model="hideWallet"
                :items="[{ label: 'Enabled', value: true }, { label: 'Disabled', value: false }]"
                label="Enable/Disable Wallet"
                item-value="value"
                item-text="label"
                outline
                required
                hide-details
                class="d-flex mt-2 w-full"
                style="align-items: center; justify-content: center;"
              />
              <v-flex xs12 class="mt-4 ">
                <v-btn
                  v-if="role !== 'user' && !userview"
                  @click="showWithdrawalProfiles = true"
                  class="plugzio-button mb-2 mt-4 wifi-setup-button py-2"
                  block
                  outline
                  style="height:100%;"
                >
                  <v-icon size="18">manage_accounts</v-icon>
                  <span class="ml-1">CONFIGURE WITHDRAWAL PROFILES</span>
                </v-btn>
              </v-flex>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <template>
            <v-btn color="gray" flat="flat" @click="handleOnCancel">Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" flat="flat" :loading="loading" @click.stop.prevent="createWallet()">{{ isEdit ? "UPDATE" : "CREATE" }}</v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <WithdrawalProfiles
      v-if="edit && showWithdrawalProfiles"
      :show="showWithdrawalProfiles"
      @input:show="showWithdrawalProfiles = $event"
      :wallet="edit"
      @success="loadWithdrawalProfiles"
    />
  </div>
</template>

<script>
import mixins from "@/mixins";
import { mapState, mapGetters } from "vuex";
import Api from "@library/apis/Api";
import CurrencyInput from "@/components/inputs/CurrencyInput.vue";
import VTooltipHandler from "@/components/VTooltipHandler";
import { ApiHelpers, OwnerProxyHelpers } from "@/library/helpers";
import WithdrawalProfiles from "./WithdrawalProfiles.vue";

export default {
  mixins: [mixins],
  props: {
    currencies: {
      type: Array,
      default: () => [],
    },
    alwaysShowButtonText: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    VTooltipHandler,
    CurrencyInput,
    WithdrawalProfiles,
  },
  data() {
    return {
      showWithdrawalProfiles: false,
      profileListChanged: false,
      createWalletModal: false,
      wallet: {
        walletId: null,
        country: null,
        currency: null,
        description: null,
        params: {
          paypalEmail: "",
          gateway: "paypal",
        },
      },
      tooltip: {
        user: `
          <ul class="ma-0 ml-3 pa-0">
            <li class="mb-2">Wallets conveniently allow you to pay for devices.</li>
            <li class="mb-2">Wallets work via a top up and pay as you go system.</li>
            <li>You will only be charged for what you use. All prices <br>
            and rates are set by the manager of the devices if applicable.</li>
          </ul>`,
        owner: `Wallets allow you to receive and store payments from users <br>as well as pay for the subscription of your device(s).`,
        hideWallet: "Hiding & disabling a wallet will cause wallets not be visible and not to show up on the payment plan options",
      },
      walletError: null,
      currencyCheck: (value) => {
        if (value) {
          let currency = value
            .toString()
            .substr(-4)
            .replace(")", "");
          return this.currencies.indexOf(currency) < 0 || "Currency already exists";
        } else {
          return "";
        }
      },
      hideWallet: false,
      valid: true,
      actionSub: null,
    };
  },
  computed: {
    ...mapState({
      responsive: (state) => state.responsive,
      preferences: (state) => state.Global.preferences || {},
      role: (state) => state.Auth.role,
      adminActiveView: (state) => state.Admin.activeView,
      managerview: (state) => state.Admin.managerview,
      userview: (state) => state.Admin.userview,
    }),
    ...mapGetters("Admin", {
      adminViewAsUser: "viewAsUser",
      viewAsOwner: "viewAsOwner",
    }),
    ...mapGetters("Wallet", {
      hiddenWallets: "hiddenWallets",
    }),
    loading() {
      return this.$store.state.loading;
    },
    edit() {
      return this.$store.state.walletEdit;
    },
    isEdit() {
      if (this.createWalletModal) {
        return this.edit && this.edit.id;
      } else return null;
    },
    currencyValidators() {
      const validators = [];
      if (this.role !== "owner" && this.adminActiveView !== "managerview") validators.push(this.currencyCheck);
      validators.push(this.rules.required);
      return validators;
    },
    allowCreate() {
      return OwnerProxyHelpers.isHaveAccessToFeature("WALLET_CREATE");
    },
  },
  methods: {
    handleOnCancel() {
      if (this.profileListChanged) this.$emit("close");
      this.createWalletModal = false;
    },
    async loadWithdrawalProfiles() {
      this.profileListChanged = true;
      try {
        await this.$store.dispatch("Wallet/fetchWithdrawalProfiles");
        if (!!this.edit) {
          this.wallet.params = this.edit.params;
        }
      } catch (error) {
        console.error("Error loading withdrawal profiles:", error);
      }
    },
    openModal() {
      this.$store.dispatch("editWallet", null);
      this.$refs.createWalletForm.reset();
    },
    createWallet() {
      if (this.$refs.createWalletForm.validate()) {
        this.walletError = false;
        this.$store.dispatch("loading", true);
        let requests = [];
        if (this.isEdit) {
          requests = [Api.walletsSetDescription(this.role, this.wallet)];
          if (this.role != "user") {
            requests.push(Api.walletsSetParams(this.role, this.wallet));
          }

          const preferences = this.managerview?.preferences || this.userview?.preferences || this.preferences || {};
          let hiddenWallets = preferences.hiddenWallets || [];
          if (!this.hideWallet) hiddenWallets = hiddenWallets.filter((v) => v !== this.wallet.walletId);
          else if (!hiddenWallets.includes(this.wallet.walletId)) hiddenWallets.push(this.wallet.walletId);

          const updatedPreferences = {
            ...preferences,
            settings: {
              darkTheme: preferences.settings?.darkTheme || false,
            },
            hiddenWallets,
          };
          const usernamePayload = ApiHelpers.getUsernamePayloadOfViewAs(this.adminActiveView, this.viewAsOwner, this.adminViewAsUser);
          const payload = {
            ...usernamePayload,
            prefs: updatedPreferences,
          };
          requests.push(Api.setPrefs(ApiHelpers.getApiPrefix(this.role, this.adminActiveView), payload));
        } else {
          this.wallet.currency = this.wallet.country
            .toString()
            .substr(-4)
            .replace(")", "");

          const apiPrefix = this.adminActiveView == "managerview" ? "admin/owner" : this.adminActiveView == "userview" ? "admin/user" : this.role;
          if (this.role === "admin") {
            if (!!this.managerview) {
              this.wallet.ownerUsername = this.managerview.owner_username;
            } else if (!!this.userview) {
              this.wallet.username = this.adminViewAsUser.username;
            } else {
              this.wallet.username = {};
            }
          }
          requests = [Api.walletsCreate(apiPrefix, this.wallet)];
        }
        Promise.all(requests)
          .then(() => {
            this.role === "admin" ? this.$store.dispatch("Admin/getPreferencesForView") : this.$store.dispatch("Global/initializePreferences");
            if (this.role !== "user" && !this.adminActiveView == "userview") this.$store.dispatch("countData");
            this.$store.dispatch("loadWallets");
            this.$store.dispatch("snackbar", {
              message: this.isEdit ? "Wallet updated" : "Wallet created",
            });
            this.$refs.createWalletForm.reset();
            this.createWalletModal = false;
            // add reload page to refresh navigation bar warning sign at the first time create wallets for users & managers
            if (this.role !== "admin" && !this.isEdit && this.currencies.length <= 0) {
              setTimeout(() => {
                window.location.reload();
              }, 500);
            }
          })
          .catch((error) => {
            this.walletCreateError(error);
          })
          .finally(() => {
            this.$store.dispatch("loading", false);
          });
      }
    },
    walletCreateError(error) {
      console.log(error);
      if (error.response && error.response.data && error.response.status === 400) {
        this.walletError = error.response.data;
      } else {
        let err = error.response && error.response.status ? error.response.status : null;
        this.walletError = "Error " + err + ". Please contact support using the 'Chat With Us' tab";
      }
    },
    checkDefaultCurrency() {
      this.$nextTick(() => {
        if (this.currencies && this.currencies.length) {
          this.wallet.country = null;
          if (this.currencies.indexOf("CAD") < 0) this.wallet.country = "Canada (CAD)";
        } else {
          this.wallet.country = "Canada (CAD)";
        }
      });
    },
  },
  watch: {
    edit() {
      if (this.isEdit) {
        this.wallet.walletId = this.edit.id;
        this.wallet.description = this.edit.description;
        // this.wallet.params.paypalEmail = this.edit.params && this.edit.params.paypalEmail ? this.edit.params.paypalEmail : null;
      }
    },
    createWalletModal(newValue, oldValue) {
      this.$nextTick(() => {
        if (newValue) {
          this.checkDefaultCurrency();
          this.hideWallet = (this.hiddenWallets || []).includes(this.wallet.walletId);
          return;
        }

        this.walletError = false;

        if (oldValue) {
          this.profileListChanged = false;
        }
      });
    },
  },
  mounted() {
    this.wallet.params.paypalEmail = localStorage.getItem("email");
    this.checkDefaultCurrency();
    this.actionSub = this.$store.subscribeAction((action) => {
      if (action.type === "editWallet") {
        this.createWalletModal = true;
      }
    });
  },
  beforeDestroy() {
    this.actionSub();
  },
};
</script>
