<template>
  <v-layout justify-center align-center>
    <v-flex v-show="menu === 'ach-profile-name'" xs12>
      <v-text-field outline label="Profile Name" v-model="achInputs.profileName" :rules="[validators.required]" required :maxlength="20" />
    </v-flex>

    <v-flex v-show="menu == 'ach-customer-information'" xs12 class="align-center justify-center pa-2 ma-2 overflow-auto">
      <h4 class="my-3">Customer Information:</h4>
      <v-form ref="achFormCustomer" class="mt-3" @submit="goNext">
        <v-text-field v-model="achInputs.customerInfo.name" label="Name" outline autocomplete="false" required class="mb-2" :rules="[validators.required]" autofocus />
        <v-text-field v-model="achInputs.customerInfo.email" label="E-mail" outline autocomplete="false" required :rules="[validators.required, validators.email]" class="mb-2" />
        <v-text-field
          v-model="achInputs.customerInfo.phoneNumber"
          label="Phone Number"
          outline
          autocomplete="false"
          required
          type="number"
          min="0"
          :rules="[validators.required, validators.noDecimalNumber, validators.phoneNumber]"
          class="mb-2"
        />
        <v-text-field v-model="achInputs.customerInfo.address" label="Address" outline required :rules="[validators.required]" class="mb-2" />
        <v-select
          v-model="achInputs.customerInfo.type"
          label="Type"
          outline
          required
          class="mb-2"
          :items="[{ value: 'Personal', text: 'Personal' }, { value: 'Business', text: 'Business' }]"
          item-text="text"
          item-value="value"
        />
      </v-form>
    </v-flex>

    <v-flex v-show="menu == 'ach-bank-information'" xs12 class="align-center justify-center pa-2 ma-2">
      <h4 class="my-3">Bank Information:</h4>
      <v-form class="mt-3" ref="achFormBank" @submit="goNext">
        <v-text-field v-model="achInputs.bankInfo.name" label="Name" outline autocomplete="false" required :rules="[validators.required]" class="mb-2" autofocus />
        <v-text-field v-model="achInputs.bankInfo.address" label="Address" outline required :rules="[validators.required]" class="mb-2" />
        <v-text-field
          v-model="achInputs.bankInfo.account"
          label="Account Number"
          outline
          type="number"
          min="0"
          required
          :rules="[validators.required, validators.noDecimalNumber, validators.allowZero]"
          class="mb-2"
        />
        <v-text-field v-model="achInputs.bankInfo.code" label="Swift Code" outline required :rules="[validators.required]" class="mb-2" />
        <v-text-field v-model="achInputs.bankInfo.abaNumber" label="ABA Number" outline required :rules="[validators.required]" class="mb-2" />
        <v-text-field v-model="achInputs.bankInfo.routing" label="Routing Number" outline required :rules="[validators.required]" class="mb-2" />
        <v-text-field v-model="achInputs.bankInfo.transit" label="Transit Number" outline class="mb-2" />
        <v-text-field v-model="achInputs.bankInfo.institution" label="Institution Number" outline class="mb-2" />
      </v-form>
    </v-flex>
  </v-layout>
</template>

<script>
import validators from "@/library/helpers/validators";
export default {
  name: "AchWithdrawalForm",
  props: {
    menu: {
      type: String,
      default: "",
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    inputs: {
      type: Object,
      default: () => ({
        profileName: null,
        customerInfo: {},
        bankInfo: {},
      }),
    },
  },
  computed: {
    validators: () => validators,
    achInputs: {
      get() {
        return this.inputs;
      },
      set(v) {
        return this.$emit("inputs:ach", v);
      },
    },
  },
  methods: {
    goNext() {
      this.$emit("submit");
    },
    resetAchInputs() {
      const resetObject = (obj) => {
        Object.keys(obj).forEach((key) => {
          if (typeof obj[key] === "object" && obj[key] !== null) {
            resetObject(obj[key]);
          } else {
            obj[key] = null;
          }
        });
      };

      resetObject(this.inputs);

      this.$emit("inputs:ach", this.inputs);
    },
  },
  watch: {
    "achInputs.customerInfo.email": function(newValue, oldValue) {
      this.$nextTick(() => {
        if (newValue && oldValue !== newValue) {
          this.achInputs.customerInfo.email = newValue.toLowerCase().trim();
        }
      });
    },
  },
  async mounted() {
    this.$nextTick(() => {
      if (!this.isEdit) {
        this.resetAchInputs();
      }
    });
  },
};
</script>
