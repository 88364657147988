<template>
  <div>
    <v-hover>
      <v-btn
        small
        dark
        color="green"
        class="plugzio-button create-button mx-0 px-3 mb-1 mt-1 mr-2"
        style="min-width: auto; height: 30%; border-radius: 30px;"
        round
        outline
        :loading="loading"
        @click="openModal()"
      >
        <v-icon size="20" class="ma-0" key="icon">file_download</v-icon>
        <span 
          class="ml-1 hidden-md-and-down" 
          key="description"
        >
          EXPORT DATA
        </span>
      </v-btn>
    </v-hover>
    <v-dialog v-model="exportDataModal" max-width="360">
      <v-card>
        <v-card-title>
          <h3>Export Wallet Data</h3>
          <VTooltipHandler
            classes="ml-2"
            icon="info"
            text="Exported file will be in CSV format. Exported data is based on excatly what is seen on the wallets page during the time of export. Changing the calendar\'s date range will affect what is exported."
          />
        </v-card-title>
        <v-alert :value="error" type="error" dismissible>{{ error }}</v-alert>
        <v-card-text>
          Date From:
          <strong>{{ storedTime.since }}</strong>
          <br />Date To:
          <strong>{{ storedTime.till }}</strong>
        </v-card-text>
        <v-card-actions>
          <template>
            <v-btn color="gray" flat="flat" @click="exportDataModal = false">CANCEL</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" flat="flat" :loading="loading" @click="exportData()">EXPORT</v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import currencies from "../../data/currency.json";
import FileHelpers from "../../library/helpers/FileHelpers";
import { mapState, mapGetters } from "vuex";
import VTooltipHandler from "@/components/VTooltipHandler";

export default {
  props: ["filter", "isOwner"],
  components: {
    VTooltipHandler,
  },
  data() {
    return {
      exportDataModal: false,
      error: false
    };
  },
  computed: {
    ...mapState({
      walletTransactions: (state) => state.walletTransactions,
      role: (state) => state.Auth.role,
      activeAdminView: (state) => state.Admin.activeView,
    }),
    ...mapGetters("Admin", {
      viewAsOwner: "viewAsOwner",
      viewAsUser: "viewAsUser",
    }),
    loading() {
      return this.$store.state.loading;
    },
    storedTime() {
      let time = {
        since: this.$moment(this.$store.getters.storedTime.since * 1000).format("YYYY-MM-DD"),
        till: this.$moment(this.$store.getters.storedTime.till * 1000).format("YYYY-MM-DD"),
      };
      return time;
    },
    walletCurrencySymbols() {
      let wallet_currency_codes = {};
      for (let index = 0; index < this.walletTransactions.length; index++) {
        const wallet = this.walletTransactions[index];
        if (typeof wallet_currency_codes[wallet.currency] == "undefined") {
          const selected_currency = currencies.filter((currency) => currency.code == wallet.currency)[0];
          if (typeof selected_currency != "undefined") {
            wallet_currency_codes[wallet.currency] = selected_currency.symbol_native;
          }
        }
      }
      return wallet_currency_codes;
    },
    filename() {
      return `Wallet Data (${this.storedTime.since} - ${this.storedTime.till})_${this.$moment().format('YYYYMMDDHHmmss')}.csv`;
    },
    username() {
      if (this.role == "admin") {
        if (this.isOwner && this.activeAdminView == "managerview" && !!this.viewAsOwner) return this.viewAsOwner.username;
        else if (!this.isOwner && this.activeAdminView == "userview" && !!this.viewAsUser) return this.viewAsUser.username;
      }
      return localStorage.getItem("email");
    },
  },
  methods: {
    openModal() {
      this.$store.dispatch("loading", true);
      setTimeout(() => {
        this.exportDataModal = true;
      }, 1000);
    },
    exportData() {
      this.$store.dispatch("loading", true);
      let moment = this.$moment;
      let walletTransactions = this.walletTransactions;
      let walletCurrencySymbols = this.walletCurrencySymbols;
      let csv =
        `${this.isOwner ? "Manager:" : "User:"},${this.username},,,,,,,,,,,\n` +
        `Date From (YYYY-MM-DD):,"${this.storedTime.since}",,,,,,,,,,,\n` +
        `Date To (YYYY-MM-DD):,"${this.storedTime.till}",,,,,,,,,,,\n` +
        `Time Zone (GMT):,"${getTimezoneOffset()}",,,,,,,,,,,\n` +
        `Total Wallets:,${walletTransactions.length},,,,,,,,,,,\n\n` +
        `${getWalletTransactionsData()},,,,,,,,,,,,\n`;

      function getWalletTransactionsData() {
        let data = "";
        walletTransactions.forEach((wallet) => {
          // let symbol = walletCurrencySymbols[wallet.currency] || "";
          let symbol = wallet.currency || "";
          let symbol_with_bracket = "";
          data += `Wallet Name:,"${wallet.description}",,,,,,,,,,,\n`;
          data += `Wallet Currency:,"${wallet.currency}",,,,,,,,,,,\n`;

          symbol_with_bracket = symbol == "" ? "" : "(" + symbol + ")";
          // data += `Wallet Amount ${symbol_with_bracket}:,"${symbol} ${
          //     wallet.balance ? formatValue(wallet.balance.toFixed(2)) : "0.00"
          // }",,,,,,,,,,,\n`;
          data += `Wallet Amount ${symbol_with_bracket}:,"${wallet.balance ? formatValue(wallet.balance.toFixed(2)) : "0.00"}",,,,,,,,,,,\n`;
          data += `Transaction Date (YYYY-MM-DD), Debit ${symbol_with_bracket}, Credit ${symbol_with_bracket}, Memo,,,,,,,,,,,\n`;
          data += extractTransactionsData(wallet.transactions, symbol);
          data += `,,,,,,,,,,,\n`;
        });
        return data;
      }

      function extractTransactionsData(transactions = [], symbol = "") {
        let data = "";
        transactions.sort((a, b) => b.record_timestamp - a.record_timestamp);
        transactions.forEach((transaction) => {
          const date = moment(transaction.record_timestamp * 1000).format("YYYY-MM-DD");
          // data += `${formatted_date},"${symbol} ${transaction.debit}","${symbol} ${transaction.credit}", ${transaction.memo},,,,,,,,,,,\n`;
          data += `${date},"${transaction.debit.toFixed(2)}","${transaction.credit.toFixed(2)}","${transaction.memo.replaceAll('"', '""')}",,,,,,,,,,,\n`;
        });
        return data;
      }

      function getTimezoneOffset() {
        let currentTime = new Date();
        let currentTimezone = currentTime.getTimezoneOffset();
        currentTimezone = (currentTimezone / 60) * -1;
        let gmt = "";
        if (currentTimezone !== 0) {
          gmt += currentTimezone > 0 ? " +" : " ";
          gmt += currentTimezone;
        }
        return gmt;
      }
      function formatValue(num) {
        if (!num) return 0;
        var parts = num.toString().split(".");
        return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
      }

      this.$store.dispatch("loading", false);
      FileHelpers.download(csv, this.filename, "text/csv");
      this.exportDataModal = false;
    }
  },
  watch: {
    exportDataModal() {
      this.$store.dispatch("loading", false);
    },
  }
};
</script>
