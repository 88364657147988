<template>
  <div>
    <v-btn
      small
      dark
      color="green"
      class="plugzio-button create-button mx-0 px-3 mb-1 mt-1"
      style="min-width: auto; height: 30%; border-radius: 30px;"
      round
      outline
      :loading="loading"
      @click="openModal()"
    >
      <v-icon size="20" class="ma-0" key="icon">file_download</v-icon>
      <span class="ml-1 hidden-md-and-down" key="description">
        EXPORT DATA
      </span>
    </v-btn>
    <v-dialog v-model="exportDataModal" max-width="360">
      <v-card>
        <v-card-title>
          <h3>Export Dashboard Data</h3>
          <v-tooltip bottom :offset-y="-10">
            <template v-slot:activator="{ on }">
              <v-icon id="export-info" class="tooltip-icon mb-0 pull-right" v-on="on">
                info
              </v-icon>
            </template>
            <span class="tooltip-content">
              Exported file will be in CSV format. Exported data is based on exactly what is seen on the dashboard during the time of export.<br />
              Changing the calendar's date range and performing a search before exporting will affect what is exported.
            </span>
          </v-tooltip>
        </v-card-title>
        <v-alert :value="error" type="error" dismissible>{{ error }}</v-alert>
        <v-card-text>
          <div class="mt-1 mb-4 ma-0 ml-0 mr-3">
            <v-select
              v-model="selectedFormat"
              id="formatOptions"
              :items="['Plugzio Format', 'NJDEP Format']"
              item-text="name"
              label="Format Options"
              outline
              required
              hide-details
              style="align-items: left; justify-content: center;"
            >
            </v-select>
          </div>
          Date From:
          <strong>{{ storedTime.since }}</strong>
          <br />Date To:
          <strong>{{ storedTime.till }}</strong>
          <br />
          <span v-if="filters && filters.search">
            Data Filtered By:
            <strong>{{ filters.search }}</strong>
          </span>
          <div class="modal-description mt-2">
            Note: You can change the calendar date and search results to filter the data you want exported.
          </div>
        </v-card-text>
        <v-card-actions>
          <template>
            <v-btn color="gray" flat="flat" @click="exportDataModal = false">CANCEL</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" :disabled="!selectedFormat" flat="flat" :loading="loading" @click="selectedFormat === 'NJDEP Format' ? exportNJDEP() : exportData()"
              >EXPORT</v-btn
            >
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { SessionHelpers, FileHelpers } from "@/library/helpers";
import { capitalize } from "lodash-es";
export default {
  props: ["filters"],
  data() {
    return {
      exportDataModal: false,
      error: false,
      selectedFormat: "Plugzio Format",
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
      stats: (state) => state.stats,
      wallets: (state) => state.walletItems,
      role: (state) => state.Auth.role,
      activeAdminView: (state) => state.Admin.activeView,
    }),
    ...mapGetters("Admin", {
      viewAsOwner: "viewAsOwner",
    }),
    storedTime() {
      let time = {
        since: this.$moment(this.$store.getters.storedTime.since * 1000).format("YYYY-MM-DD"),
        till: this.$moment(this.$store.getters.storedTime.till * 1000).format("YYYY-MM-DD"),
      };
      return time;
    },
    sessions() {
      const sessions = JSON.parse(JSON.stringify(this.$store.getters.sessions));
      if (!this.filters) return sessions;
      return SessionHelpers.filter(sessions, this.filters);
    },
    filename() {
      return `Dashboard Data (${this.storedTime.since} - ${this.storedTime.till})_${this.$moment().format("YYYYMMDDHHmmss")}.csv`;
      return `Dashboard Data (${this.storedTime.since} - ${this.storedTime.till})_${this.$moment().format("YYYYMMDDHHmmss")}.csv`;
    },
    filenameNJDEP() {
      return `Dashboard Data - NJDEP Format (${this.storedTime.since} - ${this.storedTime.till})_${this.$moment().format("YYYYMMDDHHmmss")}.csv`;
      return `Dashboard Data - NJDEP Format (${this.storedTime.since} - ${this.storedTime.till})_${this.$moment().format("YYYYMMDDHHmmss")}.csv`;
    },
    managerName() {
      if (this.role != "admin" || this.activeAdminView != "managerview" || !this.viewAsOwner) return localStorage.getItem("email");
      if (this.role != "admin" || this.activeAdminView != "managerview" || !this.viewAsOwner) return localStorage.getItem("email");
      return this.viewAsOwner.username;
    },
  },
  methods: {
    openModal() {
      this.$store.dispatch("loading", true);
      setTimeout(() => {
        this.exportDataModal = true;
      }, 1000);
    },
    exportNJDEP() {
      this.$store.dispatch("loading", true);
      let moment = this.$moment;
      let sessions = this.sessions;
      let csvNJDEP = "";

      this.$nextTick(() => {
        csvNJDEP =
          `Reporting_Entity,Data_Source_ID,Session_ID,Port_ID,Plug_In,Plug_Out,Charging_Start,Charging_End,Charging_Duration,Time Zone (GMT),Energy_Delivered,Fee\n` +
          `${getNJDEPData()}`;

        this.$store.dispatch("loading", false);
        FileHelpers.download(csvNJDEP, this.filenameNJDEP, "text/csv");
        this.exportDataModal = false;
        this.selectedFormat = "Plugzio Format";
      });

      function getNJDEPData() {
        let data = ``;
        sessions.forEach((s) => {
          data += `"",`;
          data += s.plug ? `"` + s.plug_identifier.toString() + `",` : `,`;
          data += s.plug ? `"` + s.id.toString() + `",` : `,`;
          data += `"1",`;
          data += s.plug_in_time ? `"` + moment.unix(s.plug_in_time).format(`YYYY-MM-DD hh:mm:ss A`) + `",` : `"",`;
          data += s.plug_out_time ? `"` + moment.unix(s.plug_out_time).format(`YYYY-MM-DD hh:mm:ss A`) + `",` : `"",`;
          data += '"' + moment.unix(s.starttime).format(`YYYY-MM-DD hh:mm:ss A`) + `",`;
          data += s.endtime ? '"' + moment.unix(s.endtime).format(`YYYY-MM-DD hh:mm:ss A`) + `",` : `,`;
          data += s.duration + `,`;
          data += `"` + getTimezoneOffset() + `",`;
          data += `"` + s.consumption + `",`;
          data += `"` + s.amount + `"\n`;
        });
        return data;
      }

      function getTimezoneOffset() {
        let currentTime = new Date();
        let currentTimezone = currentTime.getTimezoneOffset();
        currentTimezone = (currentTimezone / 60) * -1;
        let gmt = "";
        if (currentTimezone !== 0) {
          gmt += currentTimezone > 0 ? " +" : " ";
          gmt += currentTimezone;
        }
        return gmt;
      }
    },
    exportData() {
      this.$store.dispatch("loading", true);
      let moment = this.$moment;
      let sessions = this.sessions;
      let wallets = this.wallets;
      let csv = "";

      const totalConsumptionTileElement = document.getElementById("total-consumption-tile") || { innerText: "" };
      const totalDurationTileElement = document.getElementById("total-duration-tile") || { innerText: "" };

      this.$nextTick(() => {
        csv =
          `Manager:,${this.managerName},,,,,,,,,,,\n` +
          `Date From (YYYY-MM-DD):,"${this.storedTime.since}",,,,,,,,,,,\n` +
          `Date To (YYYY-MM-DD):,"${this.storedTime.till}",,,,,,,,,,,\n` +
          `Time Zone (GMT):,"${getTimezoneOffset()}",,,,,,,,,,,\n` +
          `Device Status:,${this.filters && this.filters.status ? capitalize(this.filters.status) : "All"},,,,,,,,,,,` +
          `${this.filters && this.filters.search ? "\nSearch Filter:, " + this.filters.search + ",,,,,,,,,,," : ""}\n` +
          `Total Sessions,"${this.stats.session}",,,,,,,,,,,\n` +
          `Total Users,"${this.stats.user}",,,,,,,,,,,\n` +
          `Total Consumption (kWh):,"${(totalConsumptionTileElement.innerText || "").replace("kWh", "")}",,,,,,,,,,,\n` +
          `Total Duration (HH:MM:SS):,${(totalDurationTileElement.innerText || "").replace("kWh", "")},,,,,,,,,,,\n` +
          `${getWalletsData()},,,,,,,,,,,,\n` +
          // `Device ID,Device Description,User,Payment Plan - Description,Payment Plan - Activation Fee ($),Payment Plan - Duration Rate ($/h),Payment Plan - Consumption Rate ($/kWh),Payment Plan - Currency,Session - Start Time (YYYY-MM-DD HH:MM:SS),Session - End Time (YYYY-MM-DD HH:MM:SS),Session - Duration (HH:MM:SS),Session - Consumption (kWh),Revenue ($)\n` +
          `Device ID,Device Description,User,Payment Plan - Description,Payment Plan - Currency,Payment Plan - Details,Session - Start Time (YYYY-MM-DD HH:MM:SS),Session - End Time (YYYY-MM-DD HH:MM:SS),Session - Duration (HH:MM:SS),Session - Consumption (kWh),Revenue ($)\n` +
          `${getSessionsData()}`;

        this.$store.dispatch("loading", false);
        FileHelpers.download(csv, this.filename, "text/csv");
        this.exportDataModal = false;
        this.selectedFormat = "Plugzio Format";
      });

      function getSessionsData() {
        let data = ``;
        sessions.forEach((s) => {
          let currencySymbol = "";
          // if (s.wallet) {
          //     s.wallet.currency === "USD" || s.wallet.currency === "CAD" ? (currencySymbol = "$ ") : (currencySymbol = "");
          // }
          data += `"` + s.plug_identifier.toString() + `",`;
          data += s.plug ? `"` + s.plug.description.toString() + `",` : `,`;
          data += s.username + `,`;
          if (s.plug_payment_access_plan) {
            let p = s.plug_payment_access_plan;
            data += p.description + `,`;
            data += s.wallet ? s.wallet.currency + `,` : ",";
            // data += p.startSessionCost > 0 ? `"` + currencySymbol + formatValue(p.startSessionCost.toFixed(2)) + `",` : "0.00,";
            // data += p.hRate > 0 ? `"` + currencySymbol + formatValue(p.hRate.toFixed(2)) + `",` : "0.00,";
            // data += p.whRate > 0 ? `"` + currencySymbol + formatValue((p.whRate * 1000).toFixed(2)) + `",` : "0.00,";
            data += `"${JSON.stringify(p).replaceAll('"', '""')}",`;
          } else {
            data += `,,,,,`;
          }
          data += '"' + moment.unix(s.starttime).format(`YYYY-MM-DD hh:mm:ss A`) + `",`;
          data += s.endtime ? '"' + moment.unix(s.endtime).format(`YYYY-MM-DD hh:mm:ss A`) + `",` : `,`;
          data += s.duration + `,`;
          data += `"` + s.consumption.replace(" kWh", "") + `",`;
          data += `"` + s.amount + `"\n`;
        });
        return data;
      }

      function getWalletsData() {
        let data = "";
        let symbol = "";
        if (sessions.length > 0) {
          wallets.forEach((wallet) => {
            // wallet.currency === "USD" || wallet.currency === "CAD" ? (symbol = "$ ") : (symbol = "");
            data += `Total Revenue (${wallet.currency}):,"${symbol}${wallet.revenue ? formatValue(wallet.revenue.toFixed(2)) : "0.00"}",,,,,,,,,,,\n`;
          });
        }
        return data;
      }

      function getTimezoneOffset() {
        let currentTime = new Date();
        let currentTimezone = currentTime.getTimezoneOffset();
        currentTimezone = (currentTimezone / 60) * -1;
        let gmt = "";
        if (currentTimezone !== 0) {
          gmt += currentTimezone > 0 ? " +" : " ";
          gmt += currentTimezone;
        }
        return gmt;
      }

      function formatValue(num) {
        if (!num) return 0;
        var parts = num.toString().split(".");
        return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
      }
    },
    download(strData, strFileName, strMimeType) {
      let D = document;
      let a = D.createElement("a");
      strMimeType = strMimeType || "application/octet-stream";

      if (navigator.msSaveBlob) {
        return navigator.msSaveBlob(new Blob([strData], { type: strMimeType }), strFileName);
      }

      if ("download" in a) {
        a.href = "data:" + strMimeType + "," + encodeURIComponent(strData);
        a.setAttribute("download", strFileName);
        a.innerHTML = "downloading...";
        D.body.appendChild(a);
        setTimeout(function() {
          a.click();
          D.body.removeChild(a);
        }, 66);
        return true;
      }

      var f = D.createElement("iframe");
      D.body.appendChild(f);
      f.src = "data:" + strMimeType + "," + encodeURIComponent(strData);

      setTimeout(function() {
        D.body.removeChild(f);
      }, 333);
      return true;
    },
  },
  watch: {
    exportDataModal() {
      this.$store.dispatch("loading", false);
    },
  },
};
</script>
