<template>
  <v-dialog v-model="visible" max-width="900" scrollable>
    <v-card style="max-height: 600px">
      <v-card-title>
        <h3>{{ globalDialog.title }}</h3>
      </v-card-title>
      <v-card-text>
        <div v-html="globalDialog.content" />
      </v-card-text>
      <v-card-actions class="flex justify-end">
        <v-btn
          v-if="globalDialog.showCancel"
          color="gray"
          flat="flat"
          @click="cancel"
        >
          {{ globalDialog.cancelText || "Cancel" }}
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="globalDialog.showConfirm"
          color="green"
          flat="flat"
          @click="confirm"
        >
          {{ globalDialog.confirmText || "OK" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: "GlobalDialog",
  computed: {
    ...mapState({
      globalDialog: state => state.Global.globalDialog
    }),
    visible: {
      get() {
        return this.globalDialog.show;
      },
      set(value) {
        if (!value) {
          this.globalDialog.onClosed()
          this.$store.dispatch("Global/reset", ["globalDialog"])
          return
        }
        this.$store.commit("Global/setState", {
          globalDialog : {
            ...this.globalDialog,
            show: value
          }
        });
      }
    },
  },
  methods: {
    confirm() {
      this.globalDialog.onConfirmed();
      this.visible = false;
    },
    cancel() {
      this.globalDialog.onCanceled();
      this.visible = false;
    }
  },
}
</script>