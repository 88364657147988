<template>
  <v-layout justify-center align-center>
    <v-flex v-show="menu === 'cheque-profile-name'" xs12>
      <v-text-field outline label="Profile Name" v-model="chequeInputs.profileName" :rules="[validators.required]" required :maxlength="20" />
    </v-flex>

    <v-flex v-show="menu === 'cheque-information'">
      <h4 class="my-3">Cheque Information:</h4>
      <v-form ref="chequeFormInfo" class="mt-3" @submit="goNext()">
        <v-text-field
          v-model="chequeInputs.chequeInfo.payToTheOrderOf"
          label="Pay To The Order Of"
          outline
          autocomplete="false"
          required
          :rules="[validators.required]"
          class="mb-2"
        />
      </v-form>
    </v-flex>

    <v-flex v-show="menu === 'cheque-mailing'">
      <h4 class="my-3">Mailing Information:</h4>
      <v-form ref="chequeFormMailing" class="mt-3" @submit="goNext()">
        <v-text-field
          v-model="chequeInputs.mailingInfo.nameOfReceiver"
          label="Name of Receiver"
          outline
          autocomplete="false"
          required
          :rules="[validators.required]"
          class="mb-2"
          autofocus
        />
        <v-text-field
          v-model="chequeInputs.mailingInfo.phoneNumber"
          label="Phone Number"
          outline
          autocomplete="false"
          required
          type="number"
          :rules="[validators.required, validators.noDecimalNumber, validators.phoneNumber]"
          class="mb-2"
        />
        <v-text-field
          v-model="chequeInputs.mailingInfo.email"
          label="E-mail Address"
          outline
          autocomplete="false"
          required
          :rules="[validators.email, validators.required]"
          class="mb-2"
        />
        <v-text-field v-model="chequeInputs.mailingInfo.address" label="Mailing Address" outline required :rules="[validators.required]" class="mb-2" />
      </v-form>
    </v-flex>
  </v-layout>
</template>

<script>
import validators from "@/library/helpers/validators";

export default {
  name: "ChequeWithdrawalForm",
  props: {
    menu: String,
    isEdit: {
      type: Boolean,
      default: false,
    },
    inputs: {
      type: Object,
      default: () => ({
        profileName: null,
        chequeInfo: {},
        mailingInfo: {},
      }),
    },
  },
  computed: {
    validators: () => validators,
    chequeInputs: {
      get() {
        return this.inputs;
      },
      set(v) {
        return this.$emit("inputs:cheque", v);
      },
    },
  },
  methods: {
    goNext() {
      this.$emit("submit");
    },
    resetChequeInputs() {
      const resetObject = (obj) => {
        Object.keys(obj).forEach((key) => {
          if (typeof obj[key] === "object" && obj[key] !== null) {
            resetObject(obj[key]);
          } else {
            obj[key] = null;
          }
        });
      };

      resetObject(this.inputs);

      this.$emit("inputs:cheque", this.inputs);
    },
  },
  watch: {
    "chequeInputs.mailingInfo.email": function(newValue, oldValue) {
      this.$nextTick(() => {
        if (newValue && oldValue !== newValue) {
          this.chequeInputs.mailingInfo.email = newValue.toLowerCase().trim();
        }
      });
    },
  },
  async mounted() {
    this.$nextTick(() => {
      if (!this.isEdit) {
        this.resetChequeInputs();
      }
    });
  },
};
</script>
