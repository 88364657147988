<template>
  <v-dialog v-model="showDialog" max-width="350">
    <v-card class="transaction-dialog">
      <v-card-title>
        <h3>Transaction Details</h3>
      </v-card-title>
      <v-card-text>
        <div>
          <p class="bill-summary text-xs-left mb-1">
            Wallet: <strong>{{ wallet ? wallet.description || '' : "" }}</strong>
            <br />
            Date: <strong>{{ selectedTransaction ? selectedTransaction.record_timestamp_text || '' : "" }}</strong>
            <br />
            Currency <strong>{{ wallet ? wallet.currency || '' : "" }}</strong>
            <br />
            Amount: <strong>$ {{ selectedTransaction ? parseFloat(selectedTransaction.money).toFixed(2)  || '' : "" }}</strong>
            <br />
            <span class="bill-summary">Memo:</span>
          </p>
          <div class="summary-note text-xs-center">
            <p>{{ selectedTransaction ? selectedTransaction.memo || '' : "" }}</p>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn color="gray" flat="flat" @click="$emit('close')">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" flat="flat" @click="copyClipboard">Copy to clipboard</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CopyToClipboard",
  props: {
    showDialog: { 
      type: Boolean, 
      default: false
    },
    wallet: {
      type: Object,
      default: () => ({}),
    },
    selected: {
      type: Number,
      default: null,
    }
  },
  computed: {
    selectedTransaction() { 
      return this.findTransactionById(this.selected); 
    }
  },
  watch: {
    selectedTransaction(v) {
      if (v) this.$emit('open');
    }
  },
  methods: {
    findTransactionById(id) {
      if (!this.wallet || !this.wallet.transactions) return null;
      else return this.wallet.transactions.find(transaction => transaction.id === id);
    },
    copyClipboard() {
      if (this.selectedTransaction) {
        const content = JSON.stringify(this.selectedTransaction);
        navigator.clipboard.writeText(content)
          .then(() => {
            this.$store.dispatch("snackbar", {
              message: "Transaction copied to clipboard",
            });
          })
          .catch((error) => {
            this.$store.dispatch("snackbar", {
              message: "Error copying transaction to clipboard",
            });
          });
        this.$emit("close");
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.transaction-dialog {
  border-radius: 10px;
}

.bill-summary {
  line-height: 1.8em;
  font-size: small;
}

.summary-note {
  font-size: 0.9em;
  padding: 1em;
  background: #f8f8f8;
}
</style> 
