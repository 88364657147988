<template>
  <div>
    <v-btn
      v-if="role === 'admin'"
      small
      dark
      color="green"
      class="plugzio-button create-button mx-2 px-3 my-1"
      style="min-width: auto; height: 30%; border-radius: 30px;"
      round
      outline
      :loading="loading"
      @click="openModal()"
    >
      <v-icon size="18" class="ma-0">cancel</v-icon>
      <span 
        class="ml-1 hidden-md-and-down" 
        key="description"
      >
        KILL SESSION
      </span>
    </v-btn>
    <v-dialog v-model="show" max-width="360">
      <v-card>
        <v-card-title>
          <h3>Kill Session</h3>
        </v-card-title>
        <v-alert :value="error" type="error" dismissible>{{ error }}</v-alert>
        <v-card-text>
          <span class="text-xs-center">
            <strong>
              You are about to forcefully terminate the charging session on Device: {{ deviceId }}. <br> 
              <br>
              This action will interupt the user's charging session. <br>
              <br>
              Are you sure you want to continue? 
            </strong> 
          </span>
        </v-card-text>
        <v-card-actions>
          <template>
            <v-btn color="gray" flat="flat" @click="show = false">CANCEL</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" flat="flat" :loading="loading" @click="killSession()">Confirm</v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Api from "@library/apis/Api";

export default {
  name: "KillSession",
  props: {
    deviceId : {
      type: String, 
      required: true
    }
  },
  data() {
    return {
      show: false,
      error: false,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
      role: (state) => state.Auth.role
    }),
  },
  watch: {
    show() {
      this.$emit("show", this.show)
    },
  },
  methods: {
    openModal() {
      this.show = true;
    },
    killSession() { 
      this.$store.dispatch("loading", true); 
      Api.adminPlugsessionkill(`{"plugIdentifier":"${this.deviceId}"}`)
        .then((adminPlugsessionkill) => {
          this.show = false;
        })
        .catch((error) => {
          this.error = error.response.data;
        })
        .finally(() => {
          this.$store.dispatch("loading", false);
        });
  },
},
}
</script>
