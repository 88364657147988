<template>
  <div>
    <v-btn
      small
      dark
      color="green"
      class="plugzio-button create-button mx-0 px-3 mb-1 mt-1"
      style="min-width: auto; height: 30%; border-radius: 30px;"
      round
      outline
      :loading="loading"
      @click="confirmation ? openModal() : exportData()"
    >
      <v-icon size="18" class="ma-0" key="icon">file_download</v-icon>
      <span 
        class="ml-1 hidden-md-and-down" 
        key="description"
      >
        EXPORT DATA
      </span>
    </v-btn>
    <v-dialog v-model="show" max-width="360">
      <v-card>
        <v-card-title>
          <h3>{{ title }}</h3>
          <VTooltipHandler classes="ml-2" icon="info" :text="tooltipText" />
        </v-card-title>
        <v-alert :value="error" type="error" dismissible>{{ error }}</v-alert>
        <v-card-text>
          <slot />
        </v-card-text>
        <v-card-actions>
          <template>
            <v-btn color="gray" flat="flat" @click="show = false">CANCEL</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" flat="flat" :loading="loading" @click="exportData()">EXPORT</v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import VTooltipHandler from "./../VTooltipHandler.vue";
import FileHelpers from './../../library/helpers/FileHelpers';
export default {
  components: {
    VTooltipHandler,
  },
  props: {
    filters: {
      default: null,
    },
    title: {
      default: "Export Data",
      type: String,
    },
    tooltipText: {
      default: "Exported file will be in CSV format",
      type: String,
    },
    filename: {
      default: "exported_data.csv",
      type: String,
    },
    data: {
      type: String,
    },
    confirmation: {
      type: Boolean,
      default: true
    },
    buttonClass: {
      type: String,
      default: `create-button mx-0 px-3`
    }
  },
  data() {
    return {
      show: false,
      error: false
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
    }),
  },
  watch: {
    show() {
      this.$store.dispatch("loading", false);
    },
  },
  methods: {
    openModal() {
      this.$store.dispatch("loading", true);
      setTimeout(() => {
        this.show = true;
      }, 1000);
    },
    exportData() {
      FileHelpers.download(this.data, this.filename, "text/csv");
      this.show = false;
    }
  }
};
</script>
