<template>
  <v-dialog v-model="show" max-width="400">
    <v-card>
      <v-card-title>
        <h3 v-html="title" />
      </v-card-title>
      <v-card-text class="text-xs-justify">
        <v-flex class="flex flex-column items-center">
          <p v-if="!$slots.text" v-html="text" />
          <slot name="text" />
        </v-flex>
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="cancelButton"
          color="gray"
          flat="flat"
          @click="$emit('cancel')"
        >
          {{ cancelButton }}
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="confirmButton"
          color="green"
          flat="flat"
          @click="$emit('confirmed')"
        >
          {{ confirmButton }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmationModal",
  props: {
    title: {
      type: String,
      default: 'Are you sure?'
    },
    text: {
      type: String,
      default: 'Navigating away from this page will cause you to lose any changes you have made.'
    },
    show: {
      type: Boolean,
      required: true
    },
    cancelButton: {
      type: String
    },
    confirmButton: {
      type: String
    }
  },
}
</script>