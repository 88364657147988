<template>
  <v-flex d-flex align-center>
    <v-btn
      small
      dark
      color="green"
      class="plugzio-button create-button mx-0 px-3 mb-0"
      style="min-width: auto"
      round
      outline
      @click="show = true"
    >
        <v-icon size="18" style="margin: 0" key="icon">add</v-icon>
        <span class="ml-1" key="description">Add Graph</span>
    </v-btn>
    <v-dialog v-model="show" max-width="400" lazy>
      <v-card>
        <v-card-title><h3>{{ stepText }}</h3></v-card-title>
        <v-card-text class="py-0 mt-4">
          <v-flex
            xs-12
            text-xs-center
          >
            <v-progress-circular
              v-if="loading"
              class="loading-circle"
              size="40"
              indeterminate
              color="black"
            />
            <DateRange
              v-else-if="step === 0"
              :default-date-range="dateRange"
              :with-time="true"
              :hide-on-mobile="false"
              :showPresets="['last_12_hours', 'last_24_hours', 'last_7_days', 'last_30_days', 'last_365_days']"
              @show="v => this.show = !v"
            />

            <v-form
              ref="dataSeriesForm"
              v-else-if="step === 1"
              @submit="nextButton.action()"
              class="text-xs-left"
            >
              <div>
                <strong>Select X-Axis Data:</strong>
                <v-autocomplete
                  v-model="axes.x"
                  :items="dataSeriesOptions"
                  item-text="text"
                  item-value="value"
                  label="X-Axis"
                  no-data-text="No axes available"
                  outline
                  required
                  :disabled="loading"
                  class="mt-2"
                  :rules="[validators.required]"
                />
              </div>
              <div class="mt-2">
                <strong>Select Y-Axis Data:</strong>
                <v-autocomplete
                  v-model="axes.y"
                  :items="dataSeriesOptions.filter(o => !['timestamp', 'uptime'].includes(o.value))"
                  item-text="text"
                  item-value="value"
                  label="Y-Axis"
                  no-data-text="No axes available"
                  outline
                  required
                  :disabled="loading"
                  class="mt-2"
                  :rules="[validators.required]"
                />
              </div>
            </v-form>
          </v-flex>
        </v-card-text>
        <v-card-actions class="mt-4" style="border-top:1px solid #eaeaea">
          <template>
            <v-btn
              v-if="backButton.enabled"
              color="black darken-1"
              flat="flat"
              :disabled="loading"
              @click="backButton.action"
            >
              {{ backButton.text }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              v-if="nextButton.enabled"
              color="green darken-1"
              flat="flat"
              :disabled="loading"
              @click="nextButton.action"
            >
              {{ nextButton.text }}
            </v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-flex>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import { cloneDeep, last } from 'lodash-es';
import validators from '@/library/helpers/validators';
import DateRange from "@/components/modals/DateRange";
export default {
  name: "HeartbeatInputModal",
  components: {
    DateRange
  },
  data: () => ({
    show: false,
    step: 0,
    axes: {
      x: null,
      y: null
    },
    dateRange: null,
    graphs: []
  }),
  computed: {
    validators: () => validators,
    ...mapState({
      loading: (state) => state.loading,
      responsive: (state) => state.responsive,
      time: (state) => state.time,
    }),
    ...mapGetters("Log", {
      logs: "data",
      parsedLogMessages: "parsedMessages",
    }),
    ...mapGetters("Admin", {
      deviceview: "formattedDeviceView",
    }),
    stepText() {
      const texts = ["Select Date & Time Range", "Select Data Series"]
      return texts[this.step]
    }, 
    nextButton() {
      const buttons = [
        {
          text: "Next",
          enabled: true,
          action: async () => {
            this.dateRange = cloneDeep(this.time)
            await this.getData({ reset: true })
            this.step++
          }
        },
        {
          text: "Confirm",
          enabled: true,
          action: () => {
            if (this.$refs.dataSeriesForm.validate()) {
              this.show = false
              this.$emit("done", {
                axes: this.axes,
                data: this.parsedLogMessages,
                timeRange: this.time
              })
            }
          }
        },
      ]
      return buttons[this.step]
    },
    backButton() {
      const buttons = [
        {
          text: "Cancel",
          enabled: true,
          action: () => {
            this.reset()
            this.show = false
          }
        },
        {
          text: "Back",
          enabled: true,
          action: () => {
            this.step--
          }
        },
      ]
      return buttons[this.step]
    },
    dataSeriesOptions() {
      return [
        {
          value: "activeEnergy",
          text: "Active Energy",
        },
        {
          value: "irms",
          text: "Irms",
        },
        {
          value: "mode",
          text: "Mode",
        },
        {
          value: "quality",
          text: "Quality",
        },
        {
          value: "reactiveEnergy",
          text: "Reactive Energy",
        },
        {
          value: "state",
          text: "State",
        },
        {
          value: "strength",
          text: "Strength",
        },
        {
          value: "t_Amb",
          text: "T_Amb",
        },
        {
          value: "t_Hot",
          text: "T_Hot",
        },
        {
          value: "timestamp",
          text: "Timestamp",
        },
        {
          value: "uptime",
          text: "Up Time",
        },
        {
          value: "vrms",
          text: "Vrms",
        },
      ]
    }
  },
  methods: {
    async getData({ reset = true, since = (this.dateRange || {}).since } = {}) {
      if (reset) {
        this.$store.dispatch("Log/set", [])
      }
      this.$store.dispatch("loading", true);

      var formData = new FormData();
      formData.append("since", since);
      formData.append("till", (this.dateRange || {}).till);
      formData.append("subdomain", "Heartbeat")
      const { internal_identifier } = this.deviceview
      if (!!internal_identifier) {
        formData.append("domain", internal_identifier)
      }

      return this.$store.dispatch("Log/getData", { formData, reset })
        .then(async (data) => {
          if ((data || []).length >= 1000) {
            const since = last(data).timestamp
            await this.getData({ reset: false, since })
          }
        })
        .catch()
        .finally(() => this.$store.dispatch("loading", false));
    },
    reset() {
      this.step = 0
      this.axes = {
        x: null,
        y: null
      }
      this.dateRange = {
        since: this.$moment().subtract(30, "day").startOf("day").unix(),
        till: this.$moment().endOf("day").unix(),
      }
    }
  },
  watch: {
    show() {
      if (this.show) this.reset()
    }
  },
  mounted() {
    this.dateRange = {
      since: this.$moment().subtract(30, "day").startOf("day").unix(),
      till: this.$moment().endOf("day").unix(),
    }
  },
};
</script>
