<template>
  <v-dialog v-model="visible" max-width="400">
    <v-card>
      <v-card-title>
        <h3>Are you sure?</h3>
      </v-card-title>
      <v-card-text class="text-xs-justify">
        <v-flex class="flex flex-column items-center">
          <p v-html="text" />
        </v-flex>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="gray"
          flat="flat"
          @click="$emit('cancel')"
        >
          Back
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="green"
          flat="flat"
          @click="$emit('confirmed')"
        >
          Exit & Discard Changes
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ExitConfirmationModal",
  props: {
    text: {
      type: String,
      default: 'Navigating away from this page will cause you to lose any changes you have made.'
    },
    show: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    visible: {
      get() {
        return this.show
      },
      set(v) {
        this.$emit("changed", v)
      }
    }
  }
}
</script>