<template>
  <div>
    <v-btn
      small
      dark
      color="green"
      class="plugzio-button create-button mx-0 px-3 mb-1"
      style="min-width: auto; height: 30%; border-radius: 30px;"
      round
      outline
      :loading="loading"
      @click="openModal()"
    >
      <v-icon size="20" class="ma-0" key="icon">file_download</v-icon>
      <span 
        class="ml-1 hidden-md-and-down" 
        key="description"
      >
        EXPORT DATA
      </span>
    </v-btn>
    <v-dialog v-model="show" max-width="300">
      <v-card>
        <v-card-title>
          <h3>Export Device Data</h3>
          <v-icon
            class="tooltip-icon ml-2"
            v-tooltip="{
              content:
                'Exported file will be in CSV format. Exported data is based on exacly what is seen on the device management during the time of export.<br>Performing a search and filter before exporting will affect what is exported',
              placement: 'top-center',
              trigger: 'click hover',
            }"
          >
            info
          </v-icon>
        </v-card-title>
        <v-alert :value="error" type="error" dismissible>{{ error }}</v-alert>
        <v-card-text>
          <div class="mt-1 mb-4 ma-0 ml-0 mr-3">
            <v-select
              id="formatOptions"
              v-model="selectedFormat"
              :items="['Plugzio Format', 'NJDEP Format']"
              item-text="name"
              label="Format Options"
              outline
              required
              hide-details
              style="align-items: left; justify-content: center;"
            >
            </v-select>
          </div>
          <div>
            <strong>Device information will be exported to CSV.</strong>
          </div>
          <div class="modal-description mt-2">
            Note: You can search for tiles to filter the data you want to export
          </div>
        </v-card-text>
        <v-card-actions>
          <template>
            <v-btn color="gray" flat="flat" @click="show = false">CANCEL</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" :disabled="!selectedFormat" flat="flat" :loading="loading" @click="selectedFormat === 'NJDEP Format' ? exportNJDEP() : exportData()">EXPORT</v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { DeviceHelpers } from '@/library/helpers';
import { mapState, mapGetters } from "vuex";
export default {
  props: {
    devices: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      show: false,
      error: false,
      selectedFormat: "Plugzio Format" 
    };
  },
  computed: {
    ...mapState({
      role: (state) => state.Auth.role,
      activeAdminView: (state) => state.Admin.activeView,
    }),
    ...mapGetters("Admin", {
      viewAsOwner: "viewAsOwner",
    }),
    loading() {
      return this.$store.state.loading;
    },
    filename() {
      const datetime = this.$moment()
        .local()
        .format("YYYY-MM-DD_HH-mm-ss");
      return `DeviceExport_${datetime}_${this.$moment().format('YYYYMMDDHHmmss')}.csv`;
    },
    filenameNJDEP() {
      const datetime = this.$moment()
        .local()
        .format("YYYY-MM-DD_HH-mm-ss");
      return `DeviceExportNJDEPFormat_${datetime}_${this.$moment().format('YYYYMMDDHHmmss')}.csv`;
    },
    managerName() {
      if (this.role != "admin" || this.activeAdminView != "managerview" || !this.viewAsOwner) return localStorage.getItem("email");
      return this.viewAsOwner.username;
    },
  },
  watch: {
    show() {
      this.$store.dispatch("loading", false);
    },
  },
  methods: {
    openModal() {
      this.$store.dispatch("loading", true);
      setTimeout(() => {
        this.show = true;
      }, 1000);
    },
    exportNJDEP() { 
      this.$store.dispatch("loading", true);
      let moment = this.$moment;
      let devices = this.devices;

      let csvNJDEP =
        `Reporting_Entity,Data_Source_ID,Last_Verified,Model_Number,Port_Count,Site_Name,Address,City,State,ZIP,Commission_Date,Operating_Status,Total_Power\n` + 
        `${getNJDEPData()}`;

      function getNJDEPData() { 
        let data = ``;

        devices.forEach((device) => {
          const deviceInfo = DeviceHelpers.parseSerialNumber(device.serial_no || "") || {}; 
          
          data += `"",`;
          data += `"` + device.identifier + `",`;
          data += `"` + moment().format("YYYY-MM-DD hh:mm:ss A") + `",`;
          data += `"` + (device.serial_no || "") + `",`;
          data += `"1",`
          data += `"",`;
          data += `"",`;
          data += `"",`;
          data += `"",`;
          data += `"",`;
          data += `"` + moment(device.start_timestamp * 1000).format("YYYY-MM-DD hh:mm:ss A") + `",`;
          data += `"${device.mode} - ${[2, 3].includes(device.plugmode) ? (device.ispublic ? "Public" : "Private") : "N/A"}",`;
          data += `"` + deviceInfo.power + `"\n`;
        });
        return data;
      }

      this.$store.dispatch("loading", false);
      this.download(csvNJDEP, this.filenameNJDEP, "text/csv");
      this.show = false;
      this.selectedFormat = "Plugzio Format";
    },
    exportData() {
      this.$store.dispatch("loading", true);
      let moment = this.$moment;
      let devices = this.devices;

      let csv =
        `Manager:,${this.managerName},,,,,,,,,,,\n` +
        `Export Date (YYYY-MM-DD):,"${moment().format("YYYY-MM-DD")}",,,,,,,,,,,\n` +
        `Export Time (YYYY-MM-DD):,"${moment().format("hh:mm:ss A")}",,,,,,,,,,,\n` +
        `Time Zone (GMT):,"${getTimezoneOffset()}",,,,,,,,,,,\n` +
        `Total Devices:,"${devices.length}",,,,,,,,,,,\n` +
        `Devices in Always On Mode:,"${devices.filter((d) => d.plugmode == 1).length}",,,,,,,,,,,\n` +
        `Devices in Always Off Mode:,"${devices.filter((d) => d.plugmode == 0).length}",,,,,,,,,,,\n` +
        `Devices in Smart Mode:,"${devices.filter((d) => d.plugmode == 2).length}",,,,,,,,,,,\n` +
        // `Devices in Bluetooth Mode:,"${devices.filter((d) => d.plugmode == 3).length}",,,,,,,,,,,\n\n` +
        `Device ID,Description,Mode,Access,Public Payment Plan,Subscription,Subscription Start (YYYY-MM-DD HH:MM:SS),Subscription End (YYYY-MM-DD HH:MM:SS),Auto Payment,Serial,Model,Communication\n` +
        `${getDeviceData()}`;

      function getTimezoneOffset() {
        let currentTime = new Date();
        let currentTimezone = currentTime.getTimezoneOffset();
        currentTimezone = (currentTimezone / 60) * -1;
        let gmt = "";
        if (currentTimezone !== 0) {
          gmt += currentTimezone > 0 ? " +" : " ";
          gmt += currentTimezone;
        }
        return gmt;
      }

      function getDeviceData() {
        let data = ``;

        devices.forEach((device) => {
          const { model = "", communication = "" } = DeviceHelpers.parseSerialNumber(device.serial_no || "") || {};

          data += `"` + device.identifier + `",`;
          data += `"` + device.description + `",`;
          data += `"` + device.mode + `",`;
          data += `"` + ([2, 3].includes(device.plugmode) ? (device.ispublic ? "Public" : "Private") : "N/A") + `",`;
          data += `"` + (!!device.plan && typeof device.plan != "undefined" ? device.plan.name : "") + `",`;
          data += `"` + (device.state == 0 ? "Expired" : "Active") + `",`;
          data += `"` + moment(device.start_timestamp * 1000).format("YYYY-MM-DD hh:mm:ss A") + `",`;
          data += `"` + moment(device.expiry_timestamp * 1000).format("YYYY-MM-DD hh:mm:ss A") + `",`;
          data += `"` + (device.payment_wallet_id ? "Enabled" : "Disabled") + `",`;
          data += `"` + (device.serial_no || "") + `",`;
          data += `"` + model + `",`;
          data += `"` + communication + `",\n`;
        });
        return data;
      }

      this.$store.dispatch("loading", false);
      this.download(csv, this.filename, "text/csv");
      this.show = false;
      this.selectedFormat = "Plugzio Format"
    },
    download(strData, strFileName, strMimeType) {
      let D = document;
      let a = D.createElement("a");
      strMimeType = strMimeType || "application/octet-stream";

      if (navigator.msSaveBlob) {
        return navigator.msSaveBlob(new Blob([strData], { type: strMimeType }), strFileName);
      }

      if ("download" in a) {
        a.href = "data:" + strMimeType + "," + encodeURIComponent(strData);
        a.setAttribute("download", strFileName);
        a.innerHTML = "downloading...";
        D.body.appendChild(a);
        setTimeout(function() {
          a.click();
          D.body.removeChild(a);
        }, 66);
        return true;
      }

      var f = D.createElement("iframe");
      D.body.appendChild(f);
      f.src = "data:" + strMimeType + "," + encodeURIComponent(strData);

      setTimeout(function() {
        D.body.removeChild(f);
      }, 333);
      return true;
    }
  }
};
</script>
